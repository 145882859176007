import { invariant } from '@epic-web/invariant';
import { Theme } from '@iheartradio/web.companion';
import { useRouteLoaderData } from '@remix-run/react';
import type {
  LoaderFunctionArgs,
  TypedResponse,
} from '@remix-run/server-runtime';

export type LoaderWithRequestInfo = ({
  request,
}: LoaderFunctionArgs) => Promise<
  TypedResponse<{
    requestInfo: {
      [k: string]: unknown;
      hints: {
        [k: string]: unknown;
        theme: Theme;
      };
      userPrefs: {
        [k: string]: unknown;
        theme: Theme;
      };
    };
  }>
>;

/**
 * @returns the request info from the root loader
 */
export function useRequestInfo<T extends LoaderWithRequestInfo>(
  rootError = false,
) {
  const data = useRouteLoaderData<T>('root');

  if (rootError) {
    // root error pages are always light theme
    return {
      hints: { theme: Theme.Light },
      userPrefs: { theme: Theme.Light },
    };
  }

  invariant(data?.requestInfo, 'No requestInfo found in root loader');

  return data.requestInfo;
}
